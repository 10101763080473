<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-30 15:51:30.169 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div class="olacred">
        <ck-header />
        <router-view />
        <ck-footer />
    </div>
</template>

<script type="text/babel">

export default {
    name: 'Index'
};

</script>

<style lang="scss" rel="stylesheet/scss">
    @import "~olacred/common/style/olacred";
</style>
